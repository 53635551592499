<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left spot">
          <div class="kw-back">
            <h3>{{ this.breadcrumb }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.INVOICING_MODULE") }}</span>
              </li>
              <li>
                <span>{{ this.breadcrumb }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="globalOrganization?.id" class="page-wrapper-header-right">
          <!-- <base-button
            class="elite-button add"
            icon
            size="sm"
            @click="openSalesInvoiceCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_SALES_INVOICES)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("SALES_INVOICES.ADD_SALES_INVOICE") }}
            </span>
          </base-button> -->
        </div>
      </div>
      <div v-if="globalOrganization?.id">
        <!-- region de tabulation -->
        <div class="ios-custom-tabs">
          <button
            class="btn"
            :class="{ active: activeTab === FOLIO_TAB }"
            @click="
              changeTab(
                FOLIO_TAB,
                $t('SALES_INVOICES.SALES_INVOICES_FOLIOS_LIST')
              )
            "
          >
            <span>{{ $t("SALES_INVOICES.SALES_INVOICES_FOLIOS_LIST") }}</span>
          </button>
          <button
            class="btn"
            :class="{ active: activeTab === INVOICE_TAB }"
            @click="
              changeTab(
                INVOICE_TAB,
                $t('SALES_INVOICES.SALES_INVOICES_LIST_SHORT')
              )
            "
          >
            <span>{{ $t("SALES_INVOICES.SALES_INVOICES_LIST_SHORT") }}</span>
          </button>
          <button
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SALES_PAYMENTS)"
            class="btn"
            :class="{ active: activeTab === TRANSFERT_TAB }"
            @click="
              changeTab(
                TRANSFERT_TAB,
                $t('SALES_INVOICES.SALES_INVOICES_TRANSFERTS_LIST')
              )
            "
          >
            <span>{{
              $t("SALES_INVOICES.SALES_INVOICES_TRANSFERTS_LIST")
            }}</span>
          </button>
        </div>
        <!-- endregion -->

        <!-- region folio -->
        <sales-invoice-list-table
          v-show="activeTab === FOLIO_TAB"
          invoice-type="folios"
          :is-opened="true"
          @onViewSalesInvoice="openSalesInvoiceViewModal"
          @onEditSalesInvoice="openSalesInvoiceEditModal"
          @onDeleteSalesInvoice="deleteSalesInvoice"
          :key="renderKey * 100"
        />
        <!-- endregion -->

        <!-- region factures -->
        <sales-invoice-list-table
          v-show="activeTab === INVOICE_TAB"
          invoice-type="invoices"
          :is-opened="false"
          @onViewSalesInvoice="openSalesInvoiceViewModal"
          @onEditSalesInvoice="openSalesInvoiceEditModal"
          @onDeleteSalesInvoice="deleteSalesInvoice"
          :key="renderKey * 101"
        />
        <!-- endregion -->

        <!-- region transferts -->
        <sales-payment-list-table
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SALES_PAYMENTS)"
          v-show="activeTab === TRANSFERT_TAB"
          @onViewSalesPayment="openSalesPaymentViewModal"
          @onViewSalesInvoice="openSalesInvoiceViewModal"
          @onEditSalesPayment="openSalesPaymentEditModal"
          @onDeleteSalesPayment="deleteSalesPayment"
          :key="renderKey * 102"
        />
        <!-- region vue details -->
        <div
          v-if="isViewSalesPaymentModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isViewSalesPaymentModalOpened ? 'show' : 'hide']"
          :key="renderKey * 200"
        >
          <div
            class="resizable-wrapper-content"
            v-resizable-modal="'SALES_PAYMENT_VIEW'"
          >
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closeSalesInvoiceModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("SALES_PAYMENTS.VIEW_SALES_PAYMENT") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <!--<notification-subscription
                  v-if="openSalesPayment"
                  :objectType="'SalesPayments'"
                  :objectId="openSalesPayment.id"
                  :events="{
                    UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                    DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                  }"
                />
                <base-dropdown
                  title-classes="btn dropdown-button"
                  menu-on-right
                  :has-toggle="false"
                >
                  <template slot="title">
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <button
                    class="edit"
                    @click="openSalesPaymentEditModal(openSalesPayment)"
                    v-if="
                      openSalesPayment.status == PAYMENT_STATUS_DRAFT &&
                      $currentUserCan($permissions.PERM_EDIT_SALES_PAYMENTS)
                    "
                  >
                    <i class="fal fa-edit"></i>
                    <span>{{ $t("COMMON.EDIT") }}</span>
                  </button>
                  <button
                    class="edit"
                    @click="deleteSalesPayment(openSalesPayment)"
                    v-if="
                      openSalesPayment.status == PAYMENT_STATUS_DRAFT &&
                      $currentUserCan($permissions.PERM_DELETE_SALES_PAYMENTS)
                    "
                  >
                    <i class="fal fa-trash-alt"></i>
                    <span>{{ $t("COMMON.DELETE") }}</span>
                  </button>
                </base-dropdown>
                <button class="close" @click="closeSalesInvoiceModal">
                  <i class="fal fa-times"></i>
                </button>-->
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <view-sales-payment-page
                v-if="openSalesPayment"
                :salesPaymentId="openSalesPayment.id"
              />
            </div>
          </div>
        </div>
        <!-- endregion -->
        <!-- endregion -->

        <!-- region details facture -->
        <div
          v-if="isViewSalesInvoiceModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isViewSalesInvoiceModalOpened ? 'show' : 'hide']"
          :key="renderKey * 200"
        >
          <div
            class="resizable-wrapper-content"
            v-resizable-modal="'SALES_INVOICE_VIEW'"
          >
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closeSalesInvoiceModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("SALES_INVOICES.VIEW_SALES_INVOICE") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <!-- <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="validateSalesInvoice(openSalesInvoice)"
                  v-if="
                    openSalesInvoiceLoaded
                      ? openSalesInvoice.items.length > 0 &&
                        openSalesInvoice.status === INVOICE_STATUS_DRAFT
                      : false
                  "
                  :title="$t('SALES_INVOICES.VALIDATE')"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-check-bold"></i>
                  </span>
                </base-button>
  
                <base-button
                  v-if="
                    openSalesInvoiceLoaded
                      ? openSalesInvoice.status === INVOICE_STATUS_VALIDATED
                      : false
                  "
                  type="primary"
                  icon
                  size="sm"
                  @click="addSalesPayment(openSalesInvoice)"
                  :title="$t('SALES_INVOICES.ADD_PAYMENT')"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-money-coins"></i>
                  </span>
                </base-button>
  
                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="viewSalesOrder(openSalesInvoice)"
                  v-if="
                    openSalesInvoiceLoaded ? openSalesInvoice.salesOrder : false
                  "
                  :title="$t('SALES_INVOICES.VIEW_ORDER')"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-bag-17"></i>
                  </span>
                </base-button>
  
                <base-button
                  type="danger"
                  icon
                  size="sm"
                  @click="cancelSalesInvoice(openSalesInvoice)"
                  v-if="canCancelSalesInvoice"
                  :title="$t('SALES_INVOICES.CANCEL')"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-fat-remove"></i>
                  </span>
                </base-button>
  
                <notification-subscription
                  v-if="openSalesInvoice"
                  :objectType="'SalesInvoices'"
                  :objectId="openSalesInvoice.id"
                  :events="{
                    UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                    DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                  }"
                />
                <base-dropdown
                  title-classes="btn dropdown-button"
                  menu-on-right
                  :has-toggle="false"
                >
                  <template slot="title">
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <button
                    class="edit"
                    @click="openSalesInvoiceEditModal(openSalesInvoice)"
                    v-if="
                      openSalesInvoice.status === INVOICE_STATUS_DRAFT &&
                      $currentUserCan($permissions.PERM_EDIT_SALES_INVOICES)
                    "
                  >
                    <i class="fal fa-edit"></i>
                    <span>{{ $t("COMMON.EDIT") }}</span>
                  </button>
                  <button
                    class="edit"
                    @click="deleteSalesInvoice(openSalesInvoice)"
                    v-if="
                      openSalesInvoice.status === INVOICE_STATUS_DRAFT &&
                      $currentUserCan($permissions.PERM_DELETE_SALES_INVOICES)
                    "
                  >
                    <i class="fal fa-trash-alt"></i>
                    <span>{{ $t("COMMON.DELETE") }}</span>
                  </button>
                </base-dropdown> 
                <button class="close" @click="closeSalesInvoiceModal">
                  <i class="fal fa-times"></i>
                </button>-->
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <view-sales-invoice-page
                v-if="openSalesInvoice"
                :salesInvoiceId="openSalesInvoice.id"
                @salesInvoiceLoaded="onSalesInvoiceLoaded"
                @customerUpdated="renderKey++"
                @onChargeDifferenceDone="renderKey++"
              />
            </div>
          </div>
        </div>
        <!-- endregion -->

        <div
          v-if="isEditSalesInvoiceModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isEditSalesInvoiceModalOpened ? 'show' : 'hide']"
          :key="renderKey * 300"
        >
          <div
            class="resizable-wrapper-content"
            v-resizable-modal="'SALES_INVOICE'"
          >
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closeSalesInvoiceModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("SALES_INVOICES.EDIT_SALES_INVOICE") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <!--<base-dropdown
                  title-classes="btn dropdown-button"
                  menu-on-right
                  :has-toggle="false"
                >
                  <template slot="title">
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <button
                    class="view"
                    @click="openSalesInvoiceViewModal(openSalesInvoice)"
                  >
                    <i class="fal fa-eye"></i>
                    <span>{{ $t("COMMON.VIEW") }}</span>
                  </button>
                </base-dropdown>
                <button class="close" @click="closeSalesInvoiceModal">
                  <i class="fal fa-times"></i>
                </button>-->
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <edit-sales-invoice-page
                v-if="openSalesInvoice"
                :salesInvoiceId="openSalesInvoice.id"
                @onViewSalesInvoice="openSalesInvoiceViewModal"
              />
            </div>
          </div>
        </div>

        <div
          v-if="isAddSalesInvoiceModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isAddSalesInvoiceModalOpened ? 'show' : 'hide']"
          :key="renderKey * 400"
        >
          <div
            class="resizable-wrapper-content"
            v-resizable-modal="'SALES_INVOICE'"
          >
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closeSalesInvoiceModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("SALES_INVOICES.ADD_SALES_INVOICE") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <add-sales-invoice-page
                :global-organization-id="this.globalOrganization.id"
                @onViewSalesInvoice="openSalesInvoiceViewModal"
              />
            </div>
          </div>
        </div>
      </div>
      <select-an-organization-message v-else />
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { PAYMENT_STATUS_DRAFT } from "@/constants/payments";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_VALIDATED,
} from "@/constants/invoices";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
// import NotificationSubscription from "@/components/NotificationSubscription.vue";
import SalesInvoiceListTable from "./partials/SalesInvoiceListTable.vue";
import EditSalesInvoicePage from "./components/EditSalesInvoiceComponent.vue";
import AddSalesInvoicePage from "./components/AddSalesInvoiceComponent.vue";
import ViewSalesInvoicePage from "./components/ViewSalesInvoiceComponent.vue";
import SalesPaymentListTable from "@/views/Pages/SalesModule/SalesPaymentManagement/partials/SalesPaymentListTable.vue";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ViewSalesPaymentPage from "@/views/Pages/SalesModule/SalesPaymentManagement/components/ViewSalesPaymentComponent.vue";
import { PAYMENT_OBJECT_TYPE } from "@/constants/payments.js";
import { INVOICE_OBJECT_TYPE } from "@/constants/invoices.js";
import WrapperExpandButton from "@/components/WrapperExpandButton";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";
import { mapGetters } from "vuex";

export default {
  layout: "DashboardLayout",

  components: {
    SelectAnOrganizationMessage,
    WrapperExpandButton,
    ViewSalesPaymentPage,
    NotificationSubscription,
    SalesPaymentListTable,
    SalesInvoiceListTable,
    // NotificationSubscription,
    EditSalesInvoicePage,
    AddSalesInvoicePage,
    ViewSalesInvoicePage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {
    canCancelSalesInvoice() {
      if (!this.openSalesInvoice) {
        return false;
      }
      if (
        this.openSalesInvoice.status === INVOICE_STATUS_CANCELED ||
        this.openSalesInvoice.status === INVOICE_STATUS_DRAFT
      ) {
        return false;
      }
      if (this.openSalesInvoice.status === INVOICE_STATUS_VALIDATED) {
        if (this.openSalesInvoice.total_paied != 0) {
          return false;
        }
        return true;
      }
      return false;
    },
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    const type = this.$route.query.type ?? INVOICE_OBJECT_TYPE;

    let isViewSalesInvoiceModalOpened = false;
    let isEditSalesInvoiceModalOpened = false;
    let isAddSalesInvoiceModalOpened = false;

    let openSalesInvoice = null;
    let isViewSalesPaymentModalOpened = false;
    let openSalesPayment = null;

    switch (type) {
      // si l'url contient un id et il s'agit d'un invoice
      case INVOICE_OBJECT_TYPE:
        {
          if (locationId && action) {
            if (action === QUERY_ACTIONS_VIEW) {
              isViewSalesInvoiceModalOpened = true;
            } else if (action === QUERY_ACTIONS_EDIT) {
              isEditSalesInvoiceModalOpened = true;
            }
            openSalesInvoice = { id: locationId };
          } else if (action === QUERY_ACTIONS_ADD) {
            isAddSalesInvoiceModalOpened = true;
          }
        }
        break;
      // si l'url contient un id et il s'agit d'un paiement
      case PAYMENT_OBJECT_TYPE:
        {
          if (locationId && action) {
            if (action === QUERY_ACTIONS_VIEW) {
              isViewSalesPaymentModalOpened = true;
            }
            openSalesPayment = { id: locationId };
          }
        }
        break;
    }

    const FOLIO_TAB = "folio";
    const INVOICE_TAB = "invoice";
    const TRANSFERT_TAB = "transfert";

    const activeTab = this.tab ?? FOLIO_TAB;

    return {
      isViewSalesPaymentModalOpened: isViewSalesPaymentModalOpened,
      isViewSalesInvoiceModalOpened: isViewSalesInvoiceModalOpened,
      isEditSalesInvoiceModalOpened: isEditSalesInvoiceModalOpened,
      isAddSalesInvoiceModalOpened: isAddSalesInvoiceModalOpened,
      PAYMENT_STATUS_DRAFT: PAYMENT_STATUS_DRAFT,
      openSalesInvoice: openSalesInvoice,
      openSalesInvoiceLoaded: false,
      openSalesPayment: openSalesPayment,
      renderKey: 1,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      INVOICE_STATUS_VALIDATED: INVOICE_STATUS_VALIDATED,
      INVOICE_STATUS_PAID: INVOICE_STATUS_PAID,
      FOLIO_TAB,
      INVOICE_TAB,
      TRANSFERT_TAB,
      activeTab,
      breadcrumb: this.$t("SALES_INVOICES.SALES_INVOICES_FOLIOS_LIST"),
    };
  },

  methods: {
    openSalesInvoiceCreateModal() {
      this.closeSalesInvoiceModal();
      this.isAddSalesInvoiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesInvoices",
          query: { action: QUERY_ACTIONS_ADD, tab: this.activeTab },
        }).href
      );
    },

    openSalesInvoiceViewModal(salesInvoice, reRender = false) {
      this.closeSalesInvoiceModal();
      this.openSalesInvoice = salesInvoice;
      this.isViewSalesInvoiceModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesInvoices",
          query: {
            id: this.openSalesInvoice.id,
            action: QUERY_ACTIONS_VIEW,
            tab: this.activeTab,
          },
        }).href
      );
    },

    onSalesInvoiceLoaded(salesInvoice, isLoaded) {
      this.openSalesInvoice = salesInvoice;
      this.openSalesInvoiceLoaded = isLoaded;
    },

    openSalesInvoiceEditModal(salesInvoice) {
      this.closeSalesInvoiceModal();
      this.openSalesInvoice = salesInvoice;
      this.isEditSalesInvoiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesInvoices",
          query: {
            id: this.openSalesInvoice.id,
            action: QUERY_ACTIONS_EDIT,
            tab: this.activeTab,
          },
        }).href
      );
    },

    closeSalesInvoiceModal() {
      this.isAddSalesInvoiceModalOpened = false;
      this.isViewSalesInvoiceModalOpened = false;
      this.isEditSalesInvoiceModalOpened = false;
      this.openSalesInvoiceLoaded = false;
      this.isViewSalesPaymentModalOpened = false;
      this.openSalesInvoice = null;
      this.openSalesInvoice = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesInvoices",
          query: {
            tab: this.activeTab,
          },
        }).href
      );
    },

    async viewSalesOrder(salesInvoice) {
      this.$router.push(this.$objectViewRoute(salesInvoice.salesOrder));
    },

    async validateSalesInvoice(salesInvoice) {
      try {
        await this.$store.dispatch("salesInvoices/validate", salesInvoice.id);
        this.openSalesInvoice =
          this.$store.getters["salesInvoices/salesInvoice"];
        this.renderKey++;
        this.$notify({
          type: "success",
          message: this.$t("SALES_INVOICES.SALES_INVOICE_VALIDATED"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cancelSalesInvoice(salesInvoice) {
      const confirmation = await swal.fire({
        title: this.$t("SALES_INVOICES.CANCEL_SALES_INVOICE_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        await this.$store.dispatch("salesInvoices/cancel", salesInvoice.id);
        this.openSalesInvoice =
          this.$store.getters["salesInvoices/salesInvoice"];
        this.renderKey++;
        this.$notify({
          type: "success",
          message: this.$t("SALES_INVOICES.SALES_INVOICE_CANCELED"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async addSalesPayment(salesInvoice) {
      this.$router.push({
        name: "Add SalesPayment",
        query: {
          invoice_id: salesInvoice.id,
          amount: salesInvoice.total_remaining_payment,
        },
      });
    },

    async deleteSalesInvoice(salesInvoice) {
      const confirmation = await swal.fire({
        title: this.$t("SALES_INVOICES.DELETE_THIS_SALES_INVOICE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("salesInvoices/destroy", salesInvoice.id);
          this.renderKey++;
          this.closeSalesInvoiceModal();
          this.$notify({
            type: "success",
            message: this.$t("SALES_INVOICES.SALES_INVOICE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    // region transferts
    openSalesPaymentViewModal(salesPayment, reRender = false) {
      this.closeSalesInvoiceModal();
      this.openSalesPayment = salesPayment;
      this.isViewSalesPaymentModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesInvoices",
          query: {
            id: this.openSalesPayment.id,
            action: QUERY_ACTIONS_VIEW,
            type: PAYMENT_OBJECT_TYPE,
            tab: this.activeTab,
          },
        }).href
      );
    },
    openSalesPaymentEditModal(salesPayment) {
      this.closeSalesInvoiceModal();
      this.openSalesPayment = salesPayment;
      this.isEditSalesPaymentModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List SalesInvoices",
          query: {
            id: this.openSalesPayment.id,
            action: QUERY_ACTIONS_EDIT,
            type: PAYMENT_OBJECT_TYPE,
            tab: this.activeTab,
          },
        }).href
      );
    },

    async deleteSalesPayment(salesPayment) {
      const confirmation = await swal.fire({
        title: this.$t("SALES_PAYMENTS.DELETE_THIS_SALES_PAYMENT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("salesPayments/destroy", salesPayment.id);
          this.renderKey++;
          this.closeSalesInvoiceModal();
          this.$notify({
            type: "success",
            message: this.$t("SALES_PAYMENTS.SALES_PAYMENT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    // endregion

    changeTab(newTab, newBreadcrumb) {
      this.activeTab = newTab;
      this.breadcrumb = newBreadcrumb;

      let href = this.$router.resolve({
        name: "List SalesInvoices",
        query: {
          tab: newTab,
        },
      }).href;

      history.pushState({}, null, href);
    },
  },
};
</script>
